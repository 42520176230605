<template>
  <div v-if="!auth.isLoggedIn">
    <LoginView />
  </div>
  <template v-else>
    <nav class="w-full bg-[#486f14] text-white">
      <div class="p-2 flex flex-row justify-between items-center">
        <div>
          <img
            src="/frontend/static/EcorusLogo-wit.svg"
            alt="Ecorus logo"
            class="h-5"
          />
        </div>
        <div class="text-sm flex gap-2">
          <div class="flex items-center">
            <UserIcon class="h-4 w-4" />
            <span>{{ auth.username }}</span>
          </div>

          <button
            class="text-xs bg-gray-200 text-black rounded-sm p-1"
            @click="auth.clearUserData()"
          >
            Log uit
          </button>
        </div>
      </div>
      <div class="flex items-end overflow-x-auto text-sm">
        <div class="my-auto px-1">
          <a href="/v1" class="text-white">
            <div class="flex items-center rounded bg-gray-400 text-xs p-1">
              <ChevronLeftIcon class="h-4 w-4 mr-1" />
              <span>V1</span>
            </div>
          </a>
        </div>
        <RouterLink
          :to="{ name: 'tickets-list' }"
          class="ml-2 md:ml-4 p-2"
          :class="currentRoute === 'ticket' && 'bg-white text-black rounded-t'"
          v-if="
            auth.roles.some(
              (r) => r.includes('tickets_v2') || r.includes('admin')
            )
          "
          >Tickets</RouterLink
        >
        <RouterLink
          :to="{ name: 'calendar' }"
          class="ml-2 md:ml-4 p-2"
          :class="
            currentRoute === 'calendar' && 'bg-white text-black rounded-t'
          "
          v-if="
            auth.roles.some(
              (r) => r.includes('tickets_v2') || r.includes('admin')
            )
          "
          >Agenda</RouterLink
        >
        <RouterLink
          :to="{ name: 'schedule' }"
          class="ml-2 md:ml-4 p-2 hidden md:block"
          :class="
            currentRoute === 'schedule' && 'bg-white text-black rounded-t'
          "
          v-if="
            auth.roles.some(
              (r) => r.includes('tickets_v2') || r.includes('admin')
            )
          "
          >Planning</RouterLink
        >
        <RouterLink
          :to="{ name: 'orders-list' }"
          class="ml-2 md:ml-4 text-white p-2"
          :class="currentRoute === 'order' && 'bg-white text-black rounded-t'"
          v-if="auth.roles.some((r) => r.includes('partner_order'))"
          >Bestellingen</RouterLink
        >
      </div>
    </nav>

    <router-view v-slot="{ Component }">
      <keep-alive :include="['TicketView', 'CalendarView']">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </template>
</template>

<script setup>
import { RouterLink, RouterView, useRoute } from 'vue-router'
import { ChevronLeftIcon } from '@heroicons/vue/20/solid'
import { UserIcon } from '@heroicons/vue/24/outline'
import { getCookie } from '@/utils'
import { computed, onBeforeMount } from 'vue'
import { useAuthStore } from '@/auth'
import LoginView from '@/views/LoginView.vue'

const route = useRoute()
const auth = useAuthStore()

onBeforeMount(async () => {
  await auth.fetchUserData(getCookie('access_token'))
})

const currentRoute = computed(() => {
  if (route.name?.includes('order')) return 'order'
  if (route.name?.includes('calendar') || route.query.from === 'calendar') {
    return 'calendar'
  }
  if (route.name?.startsWith('schedule')) return 'schedule'
  if (route.name?.includes('ticket')) return 'ticket'
})
</script>

<style>
@import '@vueform/multiselect/themes/tailwind.css';
</style>
