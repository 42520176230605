import { createRouter, createWebHistory } from 'vue-router'

const OrdersView = () => import('@/views/OrdersView.vue')
const NewOrderView = () => import('@/views/NewOrderView.vue')
const TicketView = () => import('@/views/TicketView.vue')
const CalendarView = () => import('@/views/CalendarView.vue')
const ScheduleView = () => import('@/views/ScheduleView.vue')
const ScheduleTicket = () => import('@/components/ScheduleTicket.vue')
const ScheduleStats = () => import('@/components/ScheduleStats.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/v2/',
      redirect: '/v2/tickets',
    },
    {
      path: '/v2/orders',
      name: 'orders-list',
      component: OrdersView,
    },
    {
      path: '/v2/new-order',
      name: 'new-order',
      component: NewOrderView,
    },
    {
      path: '/v2/tickets',
      name: 'tickets-list',
      component: TicketView,
    },
    {
      path: '/v2/tickets/:id',
      name: 'ticket-detail',
      component: TicketView,
    },
    {
      path: '/v2/tickets/:id/images/:image',
      name: 'ticket-view-images',
      component: TicketView,
    },
    {
      path: '/v2/calendar',
      name: 'calendar',
      component: CalendarView,
    },
    {
      path: '/v2/schedule/:year?/:month?',
      name: 'schedule',
      component: ScheduleView,
      children: [
        {
          path: 'ticket/:id',
          name: 'schedule-ticket',
          component: ScheduleTicket,
        },
        {
          path: 'stats/:week?',
          name: 'schedule-stats',
          component: ScheduleStats,
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (
      (to.name === 'ticket-detail' && from.name !== 'ticket-view-images') ||
      to.name === 'schedule-ticket'
    ) {
      return { top: 0 }
    }
    return savedPosition
  },
})

export default router
